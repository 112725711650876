<script setup>

</script>

<template>
  <div id="integrate">综合管理</div>
</template>

<style lang="scss" scoped>
#integrate {
  height: 100%;
}
</style>
